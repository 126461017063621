import React from 'react';

import { Layout } from '../components/shared/Layout';
import { Media } from '../components/pages/Media';

const MediaPage: React.FC = () => {
  return (
    <Layout padded={true}>
      <Media />
    </Layout>
  );
};

export default MediaPage;
