import React from 'react';

export const Media: React.FC = () => {
  return (
    <div className="container">
      <img src="/banners/hmw_web_banner_1.gif" />
      <br />
      <img src="/banners/hmw_web_banner_1.gif" />
    </div>
  );
};
